import React from "react"
import Image from "gatsby-image"
import styles from "../../css/gallery-card.module.css"
const GalleryCard = ({ node }) => {
  return (
    <div key={node.contentful_id} className={styles.box}>
      <Image
        key={node.contentful_id}
        fluid={node.imagen.fluid}
        className={styles.imagen}
        alt="Galería de sombreros Panama Hat"
      />
      <span>{node.nombre}</span>
    </div>
  )
}

export default GalleryCard
