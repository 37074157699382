import React from "react"
import styles from "../../css/proximamente-component.module.css"
import logo from "../../images/fondoEcomerce.svg"
import { FormattedMessage } from "gatsby-plugin-intl"

export const Prox = () => {
  return (
    <section className={styles.proximamente}>
      <img
        src={logo}
        alt="Tienda Virtual de Bravo Hats"
        className={styles.img}
      />
      <div className={styles.info}>
        <div className={styles.wrapper}>
          <h2>
            <FormattedMessage id="proximamente" />
          </h2>
          <h6>
            <FormattedMessage id="ofertas" />
          </h6>

          <h2 className={styles.specialText}>
            <FormattedMessage id="esperalo" />
          </h2>
        </div>
      </div>
    </section>
  )
}

export default Prox
