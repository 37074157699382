import React from "react"
import styles from "../../css/facts-component.module.css"
import Img from "gatsby-image"
import facts from "../../constants/facts"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
const data = graphql`
  query {
    fact: file(relativePath: { eq: "portada8.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Facts = () => {
  const factimagen = useStaticQuery(data)
  const intl = useIntl()
  return (
    <div className={styles.facts}>
      <Img
        fluid={factimagen.fact.childImageSharp.fluid}
        className={styles.img}
      />
      <div className={styles.info}>
        <p>{`${intl.formatMessage({ id: "facts1" })}`}</p>
        <h2>{`${intl.formatMessage({ id: "facts2" })}`}</h2>
        <div className={styles.services}>
          <div className={styles.center}>
            {facts.map((item, index) => {
              return (
                <article key={index} className={styles.service}>
                  <span>{item.icon}</span>
                  <h4>{item.title}</h4>
                  <h6 className={styles.desc}>{item.text}</h6>
                </article>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Facts
