import React from "react"
//import { FormattedMessage } from "gatsby-plugin-intl"
import Layout from "../components/layout-component"
import StyledHero from "../components/StyledHero"
import Banner from "../components/banner-component"
import About from "../components/Home/about-component"
import Services from "../components/Home/services-component"
import { graphql } from "gatsby"
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Gallery from "../components/Home/gallery-component"
import Categories from "../components/Home/categories-component"
import Facts from "../components/Home/facts-component"
import Prox from "../components/Home/proximamente-component"
import SEO from "../components/seo"
const Home = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      {/* {intl.formatMessage({
        id: "ejem2",
      })} */}
      <SEO
        title={`${intl.formatMessage({ id: "titleIndex" })}`}
        description={`${intl.formatMessage({ id: "descIndex" })}`}
        keywords={[
          `${intl.formatMessage({ id: "IndexKeyword1" })}`,
          `${intl.formatMessage({ id: "IndexKeyword2" })}`,
          `${intl.formatMessage({ id: "IndexKeyword3" })}`,
          `${intl.formatMessage({ id: "IndexKeyword4" })}`,
          `${intl.formatMessage({ id: "IndexKeyword5" })}`,
          `${intl.formatMessage({ id: "IndexKeyword6" })}`,
        ]}
        lang={intl.locale}
      />

      <StyledHero home="true" img={data.defaultBcg.childImageSharp.fluid}>
        <Banner
          title={<FormattedMessage id="tituloImagen" />}
          info={<FormattedMessage id="descripcionImagen" />}
        >
          <Link to="/historia-panama-hat" className="btn-white">
            <FormattedMessage id="titulobuttonHero" />
          </Link>
        </Banner>
      </StyledHero>
      <Prox />
      <About />
      <Services />
      <Gallery portadas={data.galeria.edges} />
      <Categories />
      <Facts />
    </Layout>
  )
}

export const query = graphql`
  query($locale: String) {
    defaultBcg: file(relativePath: { eq: "portada1.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    galeria: allContentfulImagenesPortadaGaleria(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          node_locale
          nombre
          contentful_id
          imagen {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
export default Home
