import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import {
  FaGlobeAmericas,
  FaCheckCircle,
  FaPalette,
  FaRedhat,
} from "react-icons/fa"

export default [
  {
    icon: <FaCheckCircle />,
    title: <FormattedMessage id="servicio1Ttulo" />,
    text: <FormattedMessage id="servicio1Texto" />,
  },
  {
    icon: <FaPalette />,
    title: <FormattedMessage id="servicio2Ttulo" />,
    text: <FormattedMessage id="servicio2Texto" />,
  },
  {
    icon: <FaRedhat />,
    title: <FormattedMessage id="servicio3Ttulo" />,
    text: <FormattedMessage id="servicio3Texto" />,
  },
  {
    icon: <FaGlobeAmericas />,
    title: <FormattedMessage id="servicio4Ttulo" />,
    text: <FormattedMessage id="servicio4Texto" />,
  },
]
