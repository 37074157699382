import React from "react"
import styles from "../../css/categories-component.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link, useIntl } from "gatsby-plugin-intl"
const data = graphql`
  query {
    mujer: file(relativePath: { eq: "mujer4.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hombre: file(relativePath: { eq: "hombre4.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Categories = () => {
  const categoriasImagenes = useStaticQuery(data)
  const intl = useIntl()
  return (
    <section className={styles.categoriaWrapper}>
      <div className={styles.effect11}>
        <Img
          fluid={categoriasImagenes.mujer.childImageSharp.fluid}
          className={styles.img}
        />

        <div className={styles.info}>
          <h5> {`${intl.formatMessage({ id: "paraEllaDes" })}`}</h5>
          <Link to="/para-ella" className={`btn-image ${styles.btn}`}>
            {`${intl.formatMessage({ id: "btnParaElla" })}`}
          </Link>
        </div>
      </div>
      <div className={styles.effect11}>
        <Img
          fluid={categoriasImagenes.hombre.childImageSharp.fluid}
          className={styles.img}
        />
        <div className={styles.info}>
          <h5> {`${intl.formatMessage({ id: "paraElDes" })}`}</h5>
          <Link to="/para-el" className={`btn-image ${styles.btn}`}>
            {`${intl.formatMessage({ id: "btnParaEl" })}`}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Categories
