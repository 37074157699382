import React from "react"
import Title from "../title-component"
import styles from "../../css/about.module.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
//el query de graphQL
const getAboutImage = graphql`
  query aboutImage {
    aboutImage: file(relativePath: { eq: "fondoSombrero2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const About = () => {
  const { aboutImage } = useStaticQuery(getAboutImage)
  return (
    <section className={styles.about}>
      <Title
        title={<FormattedMessage id="nuestraEmpresa1" />}
        subtitle={<FormattedMessage id="nuestraEmpresa2" />}
      />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="Abour company" /> */}
            <Img
              fluid={aboutImage.childImageSharp.fluid}
              alt="Sombrero de paja toquilla"
            />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>
            <FormattedMessage id="exploreDiferencia" />
          </h4>
          <p>
            <FormattedMessage id="nosotros1" />
          </p>
          <p>
            <FormattedMessage id="nosotros2" />
          </p>
          <Link to="/nuestra-empresa" className="btn-blue">
            <FormattedMessage id="masInformacion" />
          </Link>
        </article>
      </div>
    </section>
  )
}

export default About
