import React from "react"
import Title from "../title-component"
import styles from "../../css/services.module.css"
import services from "../../constants/services"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
//para google analytics
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
const Services = () => {
  return (
    <section className={styles.services}>
      <Title
        title={<FormattedMessage id="servicios1" />}
        subtitle={<FormattedMessage id="servicios2" />}
      ></Title>
      <div className={styles.center}>
        {services.map((item, index) => {
          return (
            <article key={index} className={styles.service}>
              <span>{item.icon}</span>
              <h4>{item.title}</h4>
              <p>{item.text}</p>
            </article>
          )
        })}
      </div>
      <div
        className={styles.btnWrapper}
        onClick={e => {
          e.preventDefault()
          trackCustomEvent({
            category: "Botón ver mas servicios",
            action: "Click",
            label: "Gatsby Google Analytics",
          })
        }}
        onKeyDown={e => {
          e.preventDefault()
          trackCustomEvent({
            category: "Botón ver mas servicios",
            action: "Click",
            label: "Gatsby Google Analytics",
          })
        }}
        aria-hidden="true"
      >
        <Link to="/contact" className={`${styles.btn} btn-blue`}>
          <FormattedMessage id="masInformacion" />
        </Link>
      </div>
    </section>
  )
}

export default Services
