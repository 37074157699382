import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { FaHandshake, FaGrin, FaGlobe } from "react-icons/fa"

export default [
  {
    icon: <FaHandshake />,
    title: "850+",
    text: <FormattedMessage id="sombVendidos" />,
  },
  {
    icon: <FaGrin />,
    title: "326",
    text: <FormattedMessage id="cliSatisfechos" />,
  },
  {
    icon: <FaGlobe />,
    title: "7",
    text: <FormattedMessage id="paisAlzanzados" />,
  },
]
