import React from "react"
import styles from "../../css/gallery.module.css"
import Title from "../title-component"
import { Link } from "gatsby-plugin-intl"
import GalleryCard from "./gallery-card-component"
import { FormattedMessage } from "gatsby-plugin-intl"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
const Gallery = ({ portadas }) => {
  const datosGaleria = portadas

  return (
    <section className={styles.gallery}>
      <Title
        title={<FormattedMessage id="categorias1" />}
        subtitle={<FormattedMessage id="categorias2" />}
      />
      <div className={styles.container}>
        {datosGaleria.map(({ node }) => {
          return <GalleryCard key={node.contentful_id} node={node} />
        })}
      </div>
      <div
        className={styles.btnWrapper}
        onClick={e => {
          e.preventDefault()
          trackCustomEvent({
            category: "Botón ver la galería completa",
            action: "Click",
            label: "Gatsby Google Analytics",
          })
        }}
        onKeyDown={e => {
          e.preventDefault()
          trackCustomEvent({
            category: "Botón ver la galería completa",
            action: "Click",
            label: "Gatsby Google Analytics",
          })
        }}
        aria-hidden="true"
      >
        <Link className={`${styles.btn} btn-blue`} to="/galeria">
          <FormattedMessage id="verGaleriaCompleta" />
        </Link>
      </div>
    </section>
  )
}

export default Gallery
